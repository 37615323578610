<template>
  <div>
    <div>
      <b-card>

        <b-row>

          <b-col><span>ID Number</span>&emsp;<span>:</span>&emsp;<span>{{ form.ID }}</span></b-col>

        </b-row>
        <br>
        <b-row>

          <b-col><span>Status</span>&emsp;<span>:</span>&emsp;<span style="font-weight: bold;">{{ form.status
          }}</span></b-col>
        </b-row>
        <br>
        <b-row>
          <b-col style="text-align: center;"><span style="font-weight: bold;">Selected</span></b-col>
        </b-row>

        <b-table-simple style="position: relative; text-align: center; border: 1px solid black;" responsive bordered>
          <b-th>Warranty Draft No</b-th>
          <b-th>Model</b-th>
          <b-th>Serial</b-th>
          <b-th>Barcode</b-th>
          <b-th>Status</b-th>
          <b-th>DO Number</b-th>
          <b-tr>
            <b-td>{{ form.selected.cert || '-' }}</b-td>
            <b-td>{{ form.selected.itemModel }}</b-td>
            <b-td>{{ form.selected.serial }}</b-td>
            <b-td>{{ form.selected.barcode }}</b-td>
            <b-td>{{ form.selected.warrantyStatus }}</b-td>
            <b-td>{{ form.selected.sapDo }}</b-td>
          </b-tr>

        </b-table-simple>



        <!-- <b-table-simple style="position: relative; text-align: center; border: 1px solid black;" responsive bordered>
          <b-th>DO Number</b-th>
          <b-th>Register Date</b-th>
          <b-th>General Warranty</b-th>
          <b-th>Compressor Warranty</b-th>
          <b-th>Installer</b-th>
          <b-th>Install Date</b-th>
          <b-tr>
            <b-td>{{ form.selected.sapDo }}</b-td>
            <b-td>{{ form.selected.registerDate ? form.selected.registerDate : '-' | formatDate }}</b-td>
            <b-td>{{ form.selected.generalWarrantyEndDate ? form.selected.generalWarrantyEndDate : '-' | formatDate
            }}</b-td>
            <b-td>{{ form.selected.compressorWarrantyEndDate ? form.selected.compressorWarrantyEndDate : '-' | formatDate
            }}</b-td>
            <b-td>{{ form.selected.installerName ? form.selected.installerName : '-' }}</b-td>
            <b-td>{{ form.selected.installationDate ? form.selected.installationDate : '-' | formatDate }}</b-td>
          </b-tr>


        </b-table-simple> -->


        <!-- <b-row no-gutters>

          <b-col cols="12">
            <label>Selected</label>
            <v-select class="style-chooser" v-model="form.selected" :options="form.itemList" label="barcode">
              <template slot="selected-option" slot-scope="option">{{ option.barcode }} - {{ option.serial }}</template>
              <template slot="option" slot-scope="option">{{ option.barcode }} - {{ option.itemModel }} - {{ option.serial
              }}</template>

            </v-select>
          </b-col>

          <b-col><b-button class="float-right mt-2" variant="success" @click="doUpdatePendingReview">Save
              Changes</b-button></b-col>
        </b-row> -->
      </b-card>
    </div>
    <div>
      <b-card>
        <b-col cols="12" class=" section-data" style="font-size:12pt">
          <h5>Product List</h5>

          <b-table :items="form.itemList" :fields="fields" responsive>
            <!-- <template #cell(generalWarrantyEndDate)="row">
              {{ row.item.generalWarrantyEndDate ? row.item.generalWarrantyEndDate : '-' | formatDate }}
            </template>

            <template #cell(compressorWarrantyEndDate)="row">
              {{ row.item.compressorWarrantyEndDate ? row.item.compressorWarrantyEndDate : '-' | formatDate }}
            </template>
            <template #cell(registerDate)="row">
              {{ row.item.registerDate ? row.item.registerDate : '-' | formatDate }}
            </template>
            <template #cell(installationDate)="row">
              {{ row.item.installationDate ? row.item.installationDate : '-' | formatDate }}
            </template> -->
            <template #cell(cert)="row">{{ row.item.cert || '-' }}</template>
            <template #cell(selected)="row">{{ row.item.selected || 'false' }}</template>


            <!-- <template #cell(actions)="row">
              <div class="d-flex">
                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="confirmToDeleteItem(row)" class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>

                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="resetItemStatus(row.item._id)">
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>
              </div>
            </template> -->
          </b-table>
        </b-col>

      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { sleep } from '@/utils/utils.js'
import axios from "@/axios";

export default {
  props: ["id"],
  data() {
    return {
      form: {},
      list: [],
      fields: [
        { key: "cert", label: "Warranty Draft No", filterable: true, sortable: true },
        { key: "itemModel", label: "Model", filterable: true, sortable: true },
        { key: "serial", label: "Serial", filterable: true, sortable: true },
        { key: "barcode", label: "Barcode", filterable: true, sortable: true },
        { key: "sapDo", label: "DO", filterable: true, sortable: true },
        { key: "warrantyStatus", label: "Status", filterable: true, sortable: true },
        { key: "selected", label: "Selected", filterable: true, sortable: true },
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      axios
        .get("/sales-and-purchase/pending-itemWarranty/" + this.id)
        .then((res) => {
          // console.log("res__data", res.data);
          this.form = res.data.data;
        })
        .catch((err) => console.log({ err }));
    }, 500);
  },
  methods: {
    ...mapActions({
      updatePendingReview: "PendingReview/updatePendingReview",
    }),

    doUpdatePendingReview() {
      this.form.status = 'Completed'
      return this.updatePendingReview(this.form)
        .then(async () => {
          this.$bvToast.toast("Successfully update Pending Review Item", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.go(-1);
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;

          if (errorMessage !== undefined) {
            this.$bvToast.toast(errorMessage, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Error Processing Data", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    // ...mapState({
    //   categories: (state) => {
    //     return state.category.categories.map((c) => ({
    //       value: c.id,
    //       text: c.name,
    //     }));
    //   },
    // }),

  },
};
</script>
<style scoped>
.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 30px 0 20px 0;
  height: auto;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 10px;
  background: white;
  padding: 0 10px;
}
</style>
